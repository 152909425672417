import { useForm } from "@wisr/common";
import { Action, Field, StatusCallout } from "@wisr/react-ui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getMobileRegex } from "../../forms/credit-profile/credit-profile-form.utils";
import { FormWrapper } from "../../forms/wrapper/wrapper-form.component";
import { wisrProfileGetAction } from "../../redux/wisr-profile/wisr-profile.actions";
import { useGetWisrProfile } from "../../redux/wisr-profile/wisr-profile.hooks";
import {
  analyticsCustomEvent,
  analyticsSubmitEvent,
} from "../../shared/analytics/analytics.utils";
import { isNotEmpty } from "../../shared/common.utils";
import { appInsights } from "../../shared/insights/app-insights";
import {
  EXCEPTION_ERROR_EVENT,
  USER_ERROR,
} from "../../shared/insights/app-insights.consts";
import { createAppInsightsEvent } from "../../shared/insights/app-insights.utils";
import {
  ocrClearStateAction,
  ocrCreateAction,
  ocrGetAction,
} from "../ocr.actions";
import { OCR_START_TEXT, OCR_TERMS_CONDITIONS } from "../ocr.constants";
import { useGetOcrTransaction } from "../ocr.hooks";
import { OCR_FORM_DEFAULT } from "./ocr-form.consts";
import style from "./ocr-form.scss";

export const OcrForm = () => {
  const dispatch = useDispatch();
  const ocrState = useGetOcrTransaction();
  const wisrProfile = useGetWisrProfile();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [disableRetryVerification, setDisableRetryVerification] =
    useState(true);
  const { fieldAdapter, submitAdapter, formHasErrors, setValue } =
    useForm(OCR_FORM_DEFAULT);

  const RETRY_TIMEOUT = 5000; // 5 seconds

  React.useEffect(() => {
    analyticsCustomEvent("viewedOcrForm");
  }, []);

  React.useEffect(() => {
    if (ocrState.error) {
      setFormError(ocrState.error);
      appInsights.trackEvent(
        EXCEPTION_ERROR_EVENT,
        createAppInsightsEvent({
          category: USER_ERROR,
          info: ocrState.error,
          type: "OCR error",
        })
      );
    }
  }, [ocrState.error]);

  React.useEffect(() => {
    if (ocrState.transactionId) {
      // Only get the ocrTransaction when the ID is ready
      dispatch(ocrGetAction());
      setTimeout(() => {
        setDisableRetryVerification(false);
      }, RETRY_TIMEOUT);
    }
    if (!isNotEmpty(wisrProfile)) {
      dispatch(wisrProfileGetAction());
    }
  }, [ocrState.transactionId, wisrProfile, dispatch]);

  const clearState = React.useCallback(() => {
    setValue("mobile", "");
    setFormError("");
    dispatch(ocrClearStateAction());
    analyticsCustomEvent("ocrMobileRetryButton");
    setDisableRetryVerification(true);
  }, [dispatch, setValue]);

  const submitHandler = submitAdapter((result, submitData) => {
    if (result) {
      const formatedMobile = submitData.mobile.replace(/\s+/g, "").trim();
      analyticsSubmitEvent("Ocr mobile number form");
      dispatch(
        ocrCreateAction(
          formatedMobile,
          wisrProfile ? wisrProfile.wisrCustomerId : ""
        )
      );
    }
    return Promise.resolve();
  });

  if (ocrState?.transactionId) {
    return (
      <div className="ocr-form">
        <style jsx>{style}</style>
        <FormWrapper
          hero={"Get your scores"}
          pageType={"credit"}
          formLayout={true}
          removeButton={true}
        >
          {formError ? (
            <StatusCallout type="error" message={formError} theme={"outline"} />
          ) : (
            <StatusCallout
              type="info"
              message={"Hold on tight, we're fetching your details."}
              theme={"outline"}
            />
          )}
          <div className="content">
            <h2>We&apos;ve sent you a text</h2>
            <p>
              We&apos;ve sent a text to {ocrState.mobile ?? "your mobile"} and
              once you complete the check on your phone this screen will refresh
              soon after.
            </p>
          </div>
          <div
            className={`retry ${disableRetryVerification ? "disabled" : ""}`}
          >
            <p>Didn&apos;t get it?</p>
            <Action disabled={disableRetryVerification}>
              <button
                type="button"
                onClick={clearState}
                disabled={disableRetryVerification}
              >
                Send the text again
              </button>
            </Action>
          </div>
        </FormWrapper>
      </div>
    );
  }

  return (
    <div className="ocr-form">
      <style jsx>{style}</style>
      <FormWrapper
        hero={"Get your scores"}
        pageType={"credit"}
        handleSubmit={submitHandler}
        formLayout={true}
        disableSubmit={formHasErrors()}
        buttonText={"Start ID check"}
      >
        {formError && (
          <StatusCallout type="error" message={formError} theme={"outline"} />
        )}
        {OCR_START_TEXT}
        <Field
          label="Mobile Number "
          type="mobile"
          id={`form_profile_input_mobile`}
          required={true}
          placeholder="---- --- ---"
          maxLength={12}
          {...fieldAdapter({
            name: "mobile",
            required: "Your mobile number is required",
            pattern: [
              getMobileRegex(),
              "Mobile phone number must have 10 digits, and should begin with 04",
            ],
          })}
        />
        {OCR_TERMS_CONDITIONS}
      </FormWrapper>
    </div>
  );
};
